<template>
    <div>
        <div class="ExamListText">
            视频课程
        </div>
        <div class="select">
            <el-select v-model="search.video_type" placeholder="全部">
                <el-option v-for="item in options" :key="item.value" :label="item.title" :value="item.value">
                </el-option>
            </el-select>
            <div class="search mt20">
                <input type="text" placeholder="请输入搜索内容" v-model="search.video_title">
                <div class="fs20" @click="searchFn">
                    搜索
                </div>
            </div>
        </div>
        <div class="ExamListList mt40">
            
            <div @click="$router.push(`/video?id=${item.video_id}`)" v-for="(item, index) in list" :key="index">
                <div>
                    <img :src="item.cover_src" v-if="item.cover_src" width="100%" height="100%" alt="">
                    <img src="@/../public/image/weixintupian.jpg" v-else width="100%" height="100%" alt="">
                </div>
                <div class="postionR">
                    <p class="fs18">{{ item.video_title }}</p>
                    <p class="mt10"> 课程分类：{{ item.video_type == 1 ? '公共课（必修)' : ' 专业课（选修）' }}</p>
                    <p class="mt10"> 教师：{{ item.teacher }}</p>
                    <p class="mt10 describe"> 教师介绍：{{ item.describe }}</p>
                    <p class="mt30 postionA">阅读：{{ item.reading_times }}</p>
                </div>
                <div class="before">
                    <p class="fs24">{{ item.add_time2 }}</p>
                    <p class="fs14 position">{{ item.add_time ? item.add_time.slice(0, 4) : '' }}</p>
                </div>
            </div>
        </div>
        <PagePage :pageSize="search.page_size" :total="total" @change="pageChange" @changes="sizeChange"
            :current="search.page" />
        <div v-if="is_show == 1" class="dialogBox">
            <!--  支付成功 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <div class="PaymentSucceeded">
                    <p class="fs26">无权观看</p>
                    <p class="mt20 color-2 fs20">您还不是本协会的会员</p>
                    <p class="color-2 fs20">暂时无权观看日常普及课课程</p>
                </div>
                <div class="btnBox mt20">
                    <div class="btnBox">
                        <div class="cancel" @click="is_show = 0">取消</div>
                        <div class="ml15 Submit">申请会员</div>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import PagePage from '@/components/page.vue'
import { getVideoList } from '@/api/VideoCourse'
export default {
    components: { PagePage },
    data() {
        return {

            total: 20,
            dialogVisible: true,
            list: [],
            is_show: 0,
            search: {
                page_size: 3,
                page: 1,
                video_title: '',
                video_type: '',
            },
            options: [
                {
                    title: '公共课（必修)',
                    value: 1
                },
                {
                    title: '专业课（选修）',
                    value: 2
                },
            ],
            value: ''
        }
    },
    mounted() {
        // getMajorList().then(res => {

        //     if (res.code == 200) {
        //         this.options = res.data
        //         console.log(this.options)
        //     } else {
        //         this.options = []
        //     }
        // })
        this.getVideoListFn()
    },
    methods: {
        //搜索
        searchFn() {
            this.getVideoListFn()
        },
        //获取列表
        getVideoListFn() {
            getVideoList(this.search).then(res => {
                let reg = /-/g;
                if (res.code == 200) {
                    this.list = res.data.data
                    this.total = res.data.total
                    this.list.forEach(item => {
                        item.add_time2 = item.add_time ? item.add_time.slice(4, 10) : ''
                        item.add_time2 = item.add_time2.replace(reg, ' ')
                    })
                } else {
                    this.list = []
                }
            })
        },
        pageChange(val) {
            this.search.page = val
            this.getVideoListFn()
        },
        sizeChange(val) {
            this.search.page = val
            this.getVideoListFn()
        }
    }
}
</script>

<style  scoped>
.before::before {
    content: '';
    display: inline-block;
    width: 2px;
    height: 40px;
    position: absolute;
    background: #9A9794;
    left: 20px;
}

.ExamListList>div>div:nth-child(3) p {
    width: 100%;
}

.position {
    position: relative;
    top: -50px;
}

.ExamListList>div {
    display: flex;
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 20px;
    margin-top: 20px;
    box-sizing: border-box;
}

.ExamListList>div>div:nth-child(1) {
    width: 220px;
    height: 160px;
}

.ExamListList>div>div:nth-child(2) {
    color: #000000;
    margin-left: 20px;
    font-size: 14px;
}


.ExamListText {
    justify-content: center;
    color: #CD0000;
    display: flex;
    align-items: center;
    font-size: 22px;
}

.ExamListText::before {
    content: '';
    width: 80px;
    display: inline-block;
    margin-right: 20px;
    height: 2px;
    background: #CD0000;
}

.ExamListText::after {
    content: '';
    width: 80px;
    display: inline-block;
    height: 2px;
    margin-left: 20px;
    background: #CD0000;
}

input:focus {
    outline: none;
}

.search {
    display: flex;
    justify-content: space-between;
    width: 860px;
    margin-left: 20px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
}

.search input {
    width: 100%;
    height: 100%;
    border: none;
    text-indent: 2em;
    color: #666666;

    font-size: 18px;
}

.search .fs20 {
    width: 120px;
    height: 48px;
    background: #CD0000;
    border-radius: 4px;
    color: #FFFFFF;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
}

.ExamListList>div>div:nth-child(3) {
    margin-left: auto;
    width: 110px;
    text-align: right;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.dialogBox>>>.el-dialog__title {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
}

.dialogBox>>>.el-dialog {
    width: 720px;
    background: #FFFFFF !important;
    border-radius: 10px !important;
}


.dialogBox>>>.el-dialog__header {
    width: 720px;
    box-sizing: border-box;
    height: 66px;
    border-radius: 10px 10px 0 0;
    background: #F5F5F5;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
}

.btnBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.cancel {
    width: 200px;
    height: 48px;
    background: #999999;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
}

.Submit {
    width: 200px;
    height: 48px;
    background: #CC0000;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
}



.select {
    display: flex;
    align-items: flex-end;
}

.select>>>.el-input__inner {
    width: 176px;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
}



.PaymentSucceeded {
    text-align: center;
    margin-bottom: 20px;
}

.postionR {
    position: relative;
}

.postionA {
    position: absolute;
    bottom: 5px;
}

.describe {
    width: 563px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
</style>