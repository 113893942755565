<template>
  <div class="page" :style="background">
    <el-pagination  style="margin-right:20px" :background="true" layout="total, prev, pager, next, jumper" 
    @current-change="change" :page-size="pageSize" :total="total" :current-page="current"/>
  </div>
</template>

<script>
export default {
  props: {
    // 是否背景
    background: {type: Object,default: ()=>{}},
    // 每页条数
    pageSize: {type: Number,default: 10},
    // 总条数
    total: {type: Number,default: 0},
    // 当前页
    current:{type: Number,default:1}
  },
  methods: {
    change(val) {
      this.$emit('change', val)
      document.documentElement.scrollTop = 0
    }
  },
}
</script>

<style lang="scss" scoped>
.page {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: auto;
    padding: 20px 0;
    ::v-deep .el-pagination.is-background .el-pager li:not(.disabled){
      &.active{
          background-color:#CC0000;
          color: #fff !important;
      }
      &:hover{
          color:#606266;
      }
    }
}
</style>
