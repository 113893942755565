import service from '@/utils/request2'

//获取视频列表
export function getVideoList(params){
    return service({
        url:'/api/api/curriculum/list',
        method:'get',
        params
    })
}
//获取专业
export function getMajorList(params){
    return service({
        url:'/api/api/major',
        method:'get',
        params
    })
}
//获取视频详情
export function getMajorDetails(params){
    return service({
        url:'/api/api/curriculum/info',
        method:'get',
        params
    })
}